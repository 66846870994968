import * as React from 'react';
import type { SVGProps } from 'react';
const SvgViewInAr = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
    <g opacity={0.8}>
      <path
        fill="url(#view_in_ar_svg__a)"
        d="M3 9c.825 0 1.5-.675 1.5-1.5V6c0-.825.675-1.5 1.5-1.5h1.5C8.325 4.5 9 3.825 9 3s-.675-1.5-1.5-1.5H6C3.51 1.5 1.5 3.51 1.5 6v1.5C1.5 8.325 2.175 9 3 9"
      />
      <path
        fill="url(#view_in_ar_svg__b)"
        d="M7.5 31.5H6c-.825 0-1.5-.675-1.5-1.5v-1.5c0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5V30c0 2.49 2.01 4.5 4.5 4.5h1.5c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5"
      />
      <path
        fill="url(#view_in_ar_svg__c)"
        d="M30 1.5h-1.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5H30c.825 0 1.5.675 1.5 1.5v1.5c0 .825.675 1.5 1.5 1.5s1.5-.675 1.5-1.5V6c0-2.49-2.01-4.5-4.5-4.5"
      />
      <path
        fill="url(#view_in_ar_svg__d)"
        d="M33 27c-.825 0-1.5.675-1.5 1.5V30c0 .825-.675 1.5-1.5 1.5h-1.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5H30c2.49 0 4.5-2.01 4.5-4.5v-1.5c0-.825-.675-1.5-1.5-1.5"
      />
      <path
        fill="url(#view_in_ar_svg__e)"
        d="M28.5 22.305v-8.61c0-1.08-.57-2.07-1.5-2.595l-7.5-4.32a2.96 2.96 0 0 0-1.5-.405c-.525 0-1.035.135-1.5.405L9 11.085a3.01 3.01 0 0 0-1.5 2.61v8.61c0 1.08.57 2.07 1.5 2.595l7.5 4.32c.465.27.975.405 1.5.405s1.035-.135 1.5-.405L27 24.9a2.97 2.97 0 0 0 1.5-2.595m-12 3.45-6-3.45V15.36l6 3.495zM18 16.26l-5.94-3.465L18 9.375l5.94 3.42zm7.5 6.045-6 3.45v-6.9l6-3.495z"
      />
    </g>
    <defs>
      <linearGradient
        id="view_in_ar_svg__a"
        x1={18}
        x2={18}
        y1={1.5}
        y2={34.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
      <linearGradient
        id="view_in_ar_svg__b"
        x1={18}
        x2={18}
        y1={1.5}
        y2={34.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
      <linearGradient
        id="view_in_ar_svg__c"
        x1={18}
        x2={18}
        y1={1.5}
        y2={34.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
      <linearGradient
        id="view_in_ar_svg__d"
        x1={18}
        x2={18}
        y1={1.5}
        y2={34.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
      <linearGradient
        id="view_in_ar_svg__e"
        x1={18}
        x2={18}
        y1={1.5}
        y2={34.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgViewInAr;
