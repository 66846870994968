import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStorefront = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
    <path
      fill="url(#storefront_svg__a)"
      d="M32.848 11.835 31.272 6.78c-.33-1.35-1.5-2.28-2.866-2.28H7.572c-1.35 0-2.535.945-2.85 2.28l-1.575 5.055c-.69 2.955 1.275 4.665 1.35 4.755V28.5c0 1.65 1.35 3 3 3h21c1.65 0 3-1.35 3-3V16.59c1.68-1.68 1.636-3.615 1.35-4.755M19.497 7.5h2.94l.81 5.28c.134 1.065-.585 2.22-1.92 2.22-1.005 0-1.83-.885-1.83-1.965zm-9.84 5.79c-.12.975-.9 1.71-1.816 1.71-1.395 0-2.025-1.455-1.785-2.46L7.572 7.5h2.956zm6.84-.255c0 1.08-.826 1.965-1.936 1.965-1.124 0-1.95-1.05-1.83-2.22l.825-5.28h2.94zM28.152 15c-.915 0-1.71-.735-1.815-1.71l-.87-5.79 2.896-.015 1.575 5.055c.24 1.005-.375 2.46-1.785 2.46"
      opacity={0.8}
    />
    <defs>
      <linearGradient
        id="storefront_svg__a"
        x1={18}
        x2={18}
        y1={4.5}
        y2={31.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgStorefront;
