'use client';
import {
  Text,
  Group,
  Container,
  Anchor,
  Button,
  AspectRatio,
  Box,
  Flex,
  Burger,
  Stack,
  Divider,
  RemoveScroll,
  Paper,
  UnstyledButton,
} from '@mantine/core';
import Image from 'next/image';
import classes from './ShepherdHeader.module.css';
import Link from 'next/link';
import React, { Fragment, ReactNode, useRef, useState } from 'react';
import { CaseStudiesQuery, InsuranceProductsQuery } from '../../graphql/cms/cms.generated';
import { TECHNOLOGY_ITEMS } from '../HomePage/TechnologySection';
import IconBadge from '../IconBadge/IconBadge';
import SvgMenuBook from '../../app/images/svgs/MenuBook';
import SvgPeople from '../../app/images/svgs/People';
import SvgWork from '../../app/images/svgs/Work';
import { useDisclosure } from '@mantine/hooks';
import SvgShepherdLogo from '../../app/images/svgs/ShepherdLogo';
import IconArrowRight from '../IconArrowRight';

type LinkType =
  | {
      label: string | null;
      mobileLabel: string | null;
      link: string;
      disabled?: boolean;
      type: 'link';
    }
  | {
      label: string | null;
      mobileLabel: string | null;
      links: {
        icon?: ReactNode;
        label: ReactNode;
        link: string;
        disabled?: boolean;
        mobileLabel: string;
      }[];
      type: 'menu';
      width: number;
      link?: string;
      disabled?: boolean;
    };

const ShepherdHeaderClient = ({
  productsData,
  caseStudiesData,
  isEnabled,
}: {
  productsData: InsuranceProductsQuery;
  caseStudiesData: CaseStudiesQuery;
  isEnabled: boolean;
}) => {
  const refs = useRef<(HTMLElement | null)[]>([]);
  const [opened, { toggle, close }] = useDisclosure(false);
  const [hovering, setHovering] = useState<number | null>(null);
  const [popoverOffset, setPopoverOffset] = useState<number | null>(null);
  const [dims, setDims] = useState<{ height: number; width: number } | null>(null);

  const onShowNavigation = (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    setHovering(index);
    const menuElement = refs.current[index];
    setPopoverOffset(event.currentTarget.offsetLeft);
    if (menuElement) {
      setDims({ height: menuElement.offsetHeight, width: menuElement.offsetWidth });
    }
  };

  const onHideNavigation = () => {
    setHovering(null);
    setPopoverOffset(null);
    setDims(null);
  };

  const INSURANCE_LINK: LinkType = {
    mobileLabel: 'Products',
    label: 'Products',
    type: 'menu',
    width: 450,
    links: productsData?.allInsuranceProducts?.map((item) => ({
      mobileLabel: item.title || '',
      disabled: !item.active,
      label: (
        <Flex key={item.title} gap="24px">
          <AspectRatio ratio={16 / 9} w={150} style={{ borderRadius: 8, overflow: 'hidden' }}>
            <Box pos="relative">
              <Image src={item.image1?.url || ''} alt={item.title || ''} fill sizes="200px" />
            </Box>
          </AspectRatio>
          <Box w={200}>
            <Text c="shepherdGray.9" size="lg" fw="700">
              {item.title}
            </Text>
            <Text size="sm" c="shepherdGray.5">
              {item.menuSubtitle}
            </Text>
          </Box>
        </Flex>
      ),
      link: `/products/${item.slug}`,
    })),
  };

  const SOLUTIONS_LINK: LinkType = {
    mobileLabel: 'Services',
    label: 'Services',
    type: 'menu',
    width: 450,
    links: [
      ...TECHNOLOGY_ITEMS.map((item) => ({
        mobileLabel: item.title,
        label: (
          <Flex key={item.title} gap="16px">
            <Box w={36}>
              <IconBadge icon={item.image} alt={item.title} size={36} />
            </Box>
            <Box>
              <Text c="black" size="lg" fw="700" lh="130%">
                {item.title}
              </Text>
              <Text c="shepherdGray.5" lh="100%">
                {item.subtitle}
              </Text>
            </Box>
          </Flex>
        ),
        link: item.href,
      })),
    ],
  };

  const CASE_STUDIES_LINK: LinkType = {
    mobileLabel: null,
    label: 'Case Studies',
    type: 'menu',
    width: 450,
    link: '/case-studies',
    links: [
      ...caseStudiesData?.allCaseStudyModels?.slice(0, 3).map((item) => ({
        mobileLabel: item.title || '',
        link: `/case-studies/${item.slug}`,
        label: (
          <Flex key={item.title} gap="24px" w={460}>
            <Image
              src={item.coverImage?.url || ''}
              alt={item.title || ''}
              style={{ borderRadius: 8, overflow: 'hidden' }}
              width={144}
              height={80}
            />

            <Box style={{ flex: 1 }}>
              <Text c="shepherdGray.9" fw="700" lineClamp={2} lh="130%">
                {item.title}
              </Text>
              <Text size="sm" c="shepherdGray.5" lineClamp={2} lh="130%">
                {item.excerpt}
              </Text>
            </Box>
          </Flex>
        ),
      })),
      {
        mobileLabel: 'View all case studies',
        label: (
          <Group gap={2}>
            <Text fw={700} size="lg" c="shepherdGray.9">
              View all case studies
            </Text>
            <IconArrowRight />
          </Group>
        ),
        link: '/case-studies',
      },
    ],
  };

  const COMPANY_LINK: LinkType = {
    mobileLabel: 'Company',
    label: 'Company',
    type: 'menu',
    width: 300,
    links: [
      {
        mobileLabel: 'Case Studies',
        label: null,
        link: '/case-studies',
      },
      {
        icon: <SvgMenuBook width={32} height={32} />,
        mobileLabel: 'Blog',
        label: (
          <Box>
            <Text fw={600}>Blog</Text>
            {/* <Text c="shepherdGray.5" size="sm">

            </Text> */}
          </Box>
        ),
        link: '/blog',
      },
      {
        mobileLabel: 'Team',
        icon: <SvgPeople width={32} height={32} />,
        label: (
          <Box>
            <Text fw={600}>Team</Text>
            {/* <Text c="shepherdGray.5" size="sm">
              Meet Shepherd
            </Text> */}
          </Box>
        ),
        link: '/team',
      },
      {
        mobileLabel: 'Careers',
        icon: <SvgWork width={32} height={32} />,
        label: (
          <Box>
            <Text fw={600}>Careers</Text>
            {/* <Text c="shepherdGray.5" size="sm">
              Hiring and openings
            </Text> */}
          </Box>
        ),
        link: '/careers',
      },
    ],
  };

  const links: LinkType[] = [INSURANCE_LINK, SOLUTIONS_LINK, CASE_STUDIES_LINK, COMPANY_LINK];

  const items = links.map((link, index) => {
    if (link.type === 'menu' && link.label) {
      if (link.link) {
        return (
          <UnstyledButton
            key={link.label}
            href={link.link}
            className={classes.link}
            component={Link}
            onMouseOver={(event) => {
              onShowNavigation(event, index);
            }}
            aria-disabled={link.disabled}
          >
            {link.label}
          </UnstyledButton>
        );
      }

      return (
        <UnstyledButton
          key={link.label}
          className={classes.link}
          onMouseOver={(event) => {
            onShowNavigation(event, index);
          }}
          aria-disabled={link.disabled}
        >
          {link.label}
        </UnstyledButton>
      );
    }

    if (link.type === 'link' && link.label) {
      return (
        <UnstyledButton
          component={Link}
          key={link.label}
          href={link.link}
          className={classes.link}
          onMouseOver={() => onHideNavigation()}
          aria-disabled={link.disabled}
        >
          {link.label}
        </UnstyledButton>
      );
    }
  });

  const mobileItems = links.map((link) => {
    if (link.type === 'menu' && link.mobileLabel) {
      return (
        <Fragment key={link.label}>
          <Stack py={12} key={link.mobileLabel} gap={4}>
            <Text fw={600} size="lg">
              {link.mobileLabel}
            </Text>
            {link.links?.map(
              (item) =>
                !item.disabled && (
                  <Anchor
                    key={item.link}
                    component={Link}
                    href={item.link}
                    c="shepherdGray.5"
                    onClick={() => close()}
                    py={2}
                  >
                    {item.mobileLabel}
                  </Anchor>
                )
            )}
          </Stack>
          <Divider />
        </Fragment>
      );
    }

    if (link.type === 'link' && link.mobileLabel) {
      return (
        <Anchor
          component={Link}
          key={link.mobileLabel}
          href={link.link}
          className={classes.link}
          c="shepherdGray.5"
          onClick={() => close()}
          py={2}
        >
          {link.mobileLabel}
        </Anchor>
      );
    }
  });

  return (
    <>
      <header
        className={`${classes.header} ${opened ? classes.headerOpened : ''}`}
        onMouseLeave={() => {
          onHideNavigation();
        }}
      >
        <Container size="xl">
          <div className={classes.inner}>
            <Link href="/" style={{ height: 32 }}>
              <SvgShepherdLogo width={133} height={32} />
              {/* <Image src={shepherdLogo} alt="logo" width={133} loading="eager" /> */}
            </Link>
            <Group gap="0px" visibleFrom="sm">
              {items}
              {isEnabled && (
                <Anchor href="/api/disable-draft" className={classes.link}>
                  Exit Draft
                </Anchor>
              )}
            </Group>
            <Group w={133} justify="end">
              <Button
                variant="blackGradient"
                visibleFrom="sm"
                component="a"
                href="mailto:support@withshepherd.com"
                target="_blank"
              >
                Contact us
              </Button>
            </Group>
            <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
          </div>

          <RemoveScroll enabled={opened}>
            <Box className={[classes.drawer, opened ? classes.drawerOpen : ''].join(' ')}>
              <Box className={classes.drawerInner}>{mobileItems}</Box>
            </Box>
          </RemoveScroll>
        </Container>
        {
          <Box
            pos="absolute"
            left={popoverOffset || undefined}
            style={{
              transition: 'left 0.2s ease-in-out, width 0.2s ease, height 0.2s ease',
              visibility: hovering !== null ? 'visible' : 'hidden',
            }}
            opacity={hovering !== null ? 1 : 0}
            // display={displayed ? 'block' : 'none'}
            w={dims?.width || undefined}
            h={dims?.height || undefined}
            visibleFrom="sm"
          >
            <Paper shadow="sm" w="100%" h="100%" style={{ borderRadius: 8, overflow: 'clip' }}>
              {links.map((link, index) => {
                if (link.type === 'menu' && link.label) {
                  return (
                    <Box
                      opacity={hovering === index ? 1 : 0}
                      // display={hovering === index ? 'block' : 'none'}
                      style={{
                        transition: 'opacity 0.2s ease-in-out',
                        zIndex: hovering === index ? 1 : 0,
                      }}
                      key={link.label}
                      pos="absolute"
                      w={link.width}
                      ref={(ref) => (refs.current[index] = ref)}
                    >
                      <Box p={16}>
                        {link.links?.map(
                          (item) =>
                            item.label && (
                              <UnstyledButton
                                key={item.link}
                                // @ts-expect-error
                                component={item.disabled ? 'div' : Link}
                                href={item.link}
                                c="shepherdGray.9"
                                p="sm"
                                className={`${classes.menuItem} ${
                                  item.disabled ? '' : classes.menuItemHover
                                }`}
                                aria-disabled={item.disabled}
                              >
                                <Group wrap="nowrap">
                                  {item.icon}
                                  {item.label}
                                </Group>
                              </UnstyledButton>
                            )
                        )}
                      </Box>
                    </Box>
                  );
                }

                return null;
              })}
            </Paper>
          </Box>
        }
      </header>
    </>
  );
};

export default ShepherdHeaderClient;
