'use client';
import Script from 'next/script';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import React from 'react';

const ShepherdAnalytics = () => {
  return (
    <div>
      <>
        <GoogleAnalytics trackPageViews gaMeasurementId="G-ZZPJEGHYZE" />

        <Script
          async
          src="https://tag.clearbitscripts.com/v1/pk_5e76a69ea8789546479df5f00cf74093/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        />
      </>
    </div>
  );
};

export default ShepherdAnalytics;
