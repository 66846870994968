import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHotelClass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
    <path
      fill="url(#hotel_class_svg__a)"
      d="M12.36 14.708H3.33c-.735 0-1.035.93-.435 1.365l7.365 5.265-2.835 9.15c-.21.69.585 1.26 1.17.825l7.395-5.64 7.395 5.625c.585.435 1.38-.12 1.17-.825l-2.835-9.15 7.365-5.265c.6-.42.3-1.365-.435-1.365h-9.03l-2.925-9.63c-.21-.705-1.215-.705-1.44 0zm18.48 15.765-2.1-6.78 4.365-3.12c.6-.42.3-1.365-.435-1.365h-2.82l-4.62 3.3 2.19 7.08 2.25 1.71c.585.435 1.38-.135 1.17-.825m-5.85-18.765-2.01-6.63c-.21-.705-1.215-.705-1.44 0l-.855 2.805 1.17 3.825z"
      opacity={0.8}
    />
    <defs>
      <linearGradient
        id="hotel_class_svg__a"
        x1={18}
        x2={18}
        y1={4.535}
        y2={31.465}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgHotelClass;
