import { Box, Button, Container, SimpleGrid, Stack, Text } from '@mantine/core';
import React from 'react';
import SectionHeader from '../SectionHeader';

import IconBadge from '../IconBadge/IconBadge';
import IconArrowRight from '../IconArrowRight';
import SvgBarChart from '../../app/images/svgs/BarChart';
import SvgHotelClass from '../../app/images/svgs/HotelClass';
import SvgStorefront from '../../app/images/svgs/Storefront';
import SvgViewInAr from '../../app/images/svgs/ViewInAr';
import Link from 'next/link';

export const TECHNOLOGY_ITEMS = [
  {
    image: SvgHotelClass,
    title: 'Savings',
    subtitle: 'Premium credit program for innovators',
    href: '/solutions/savings',
  },
  {
    image: SvgViewInAr,
    title: 'AI Compliance',
    subtitle: 'Certificate and endorsement workflow solution',
    href: '/solutions/ai-compliance',
  },
  {
    image: SvgBarChart,
    title: 'Insights',
    subtitle: 'Exposure assessments and loss benchmarking',
    href: '/solutions/insights',
  },

  {
    image: SvgStorefront,
    title: 'Marketplace',
    subtitle: 'Over $125,000 in discounts from curated technology partners',
    href: '/solutions/marketplace',
  },
];

const TechnologySection = () => {
  return (
    <Container size="xl">
      <Stack gap="94px">
        <SectionHeader
          badge="Technology"
          title="Insurance as a platform, not just a product "
          subtitle="We build, maintain, and support software alongside all our insurance products to create win-win outcomes for clients and brokers."
        />
        <SimpleGrid cols={{ base: 1, md: 2 }}>
          {TECHNOLOGY_ITEMS.map((item) => (
            <Box key={item.title} p="24px" bg="shepherdGray.1" style={{ borderRadius: '8px' }}>
              <IconBadge icon={item.image} alt={item.title} />
              <Text c="black" size="lg" pt="12px" fw="700">
                {item.title}
              </Text>
              <Text c="shepherdGray.5">{item.subtitle}</Text>
              <Box pt="8px">
                <Button
                  color="shepherdGray.3"
                  c="shepherdGray.9"
                  radius="xl"
                  rightSection={<IconArrowRight />}
                  component={Link}
                  href={item.href}
                >
                  Learn more
                </Button>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  );
};

export default TechnologySection;
