import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBarChart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props}>
    <path
      fill="url(#bar_chart_svg__a)"
      d="M9.6 13.8h.3c1.155 0 2.1.945 2.1 2.1v10.5c0 1.155-.945 2.1-2.1 2.1h-.3c-1.155 0-2.1-.945-2.1-2.1V15.9c0-1.155.945-2.1 2.1-2.1M18 7.5c1.155 0 2.1.945 2.1 2.1v16.8c0 1.155-.945 2.1-2.1 2.1s-2.1-.945-2.1-2.1V9.6c0-1.155.945-2.1 2.1-2.1m8.4 12c1.155 0 2.1.945 2.1 2.1v4.8c0 1.155-.945 2.1-2.1 2.1s-2.1-.945-2.1-2.1v-4.8c0-1.155.945-2.1 2.1-2.1"
      opacity={0.8}
    />
    <defs>
      <linearGradient
        id="bar_chart_svg__a"
        x1={18}
        x2={18}
        y1={7.5}
        y2={28.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFCFD" />
        <stop offset={1} stopColor="#98A2B3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgBarChart;
