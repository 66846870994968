import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenuBook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 24" {...props}>
    <g fill="#667085" opacity={0.8}>
      <path d="M16.411 4.198c-1.828 0-3.797.375-5.156 1.406-1.36-1.031-3.328-1.406-5.156-1.406-1.36 0-2.803.206-4.013.74-.684.31-1.144.975-1.144 1.735v10.575a1.87 1.87 0 0 0 2.325 1.818A11.6 11.6 0 0 1 6.1 18.73c1.462 0 3.018.244 4.275.862.562.282 1.2.282 1.753 0 1.256-.628 2.812-.862 4.275-.862.937 0 1.912.103 2.831.337 1.181.31 2.325-.59 2.325-1.818V6.673c0-.76-.46-1.425-1.144-1.735-1.2-.534-2.644-.74-4.003-.74m3.281 11.934c0 .59-.543 1.022-1.125.919a12 12 0 0 0-2.156-.188c-1.594 0-3.89.61-5.156 1.407V7.479c1.265-.797 3.562-1.406 5.156-1.406.863 0 1.716.084 2.531.262.432.094.75.478.75.919z" />
      <path d="M13.111 10.3a.69.69 0 0 1-.666-.487.697.697 0 0 1 .45-.881c1.444-.469 3.31-.619 5.026-.422a.704.704 0 0 1 .618.778.704.704 0 0 1-.778.619c-1.519-.178-3.178-.037-4.434.366-.075.009-.15.028-.216.028M13.111 12.795a.69.69 0 0 1-.666-.488.697.697 0 0 1 .45-.881c1.435-.469 3.31-.619 5.026-.422a.704.704 0 0 1 .618.778.704.704 0 0 1-.778.619c-1.519-.178-3.178-.038-4.434.365a1 1 0 0 1-.216.028M13.111 15.288a.69.69 0 0 1-.666-.487.697.697 0 0 1 .45-.882c1.435-.468 3.31-.618 5.026-.421a.704.704 0 0 1 .618.778.71.71 0 0 1-.778.619c-1.519-.179-3.178-.038-4.434.365a1 1 0 0 1-.216.028" />
    </g>
  </svg>
);
export default SvgMenuBook;
