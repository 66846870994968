'use client';
import { Text, Container, ActionIcon, Group, Anchor, SimpleGrid, Box, Flex } from '@mantine/core';
import Image from 'next/image';
import shepherdLogo from '../../app/images/svgs/shepherd-logo.svg';
import classes from './Footer.module.css';
import SvgTwitter from '../../app/images/svgs/Twitter';
import SvgLinkedin from '../../app/images/svgs/Linkedin';
import Link from 'next/link';

const data = [
  {
    title: 'Company',
    links: [
      { label: 'Team', link: '/team' },
      { label: 'News', link: '/blog' },
      { label: 'Careers', link: '/careers' },
      // { label: 'Contact us', link: '#', href: 'mailto:support@withshepherd.com' },
      // { label: 'Licenses', link: '/licenses' },
      // { label: 'Licenses', link: '#' },
      // { label: 'Support', link: '#' },
      // { label: 'Forums', link: '#' },
    ],
  },
  {
    title: 'Legal',
    links: [
      // { label: 'Contact us', link: '#', href: 'mailto:support@withshepherd.com' },
      { label: 'Licenses', link: '/licenses' },
      {
        label: 'Terms of Service',
        link: 'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Terms+of+Use.pdf',
      },
      {
        label: 'Privacy',
        link: 'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Privacy+Policy.pdf',
      },
      // { label: 'Licenses', link: '#' },
      // { label: 'Support', link: '#' },
      // { label: 'Forums', link: '#' },
    ],
  },
  {
    title: 'Contact',
    links: [
      { label: 'Support', link: 'mailto:support@withshepherd.com' },
      { label: 'Submissions', link: 'mailto:submissions@withshepherd.com' },
      {
        label: <>CA License #6006629</>,
      },
    ],
  },
];

const Footer = () => {
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => {
      if (link.link) {
        if (link.link.startsWith('/')) {
          return (
            <Anchor key={index} className={classes.link} component={Link} href={link.link}>
              {link.label}
            </Anchor>
          );
        }

        return (
          <Anchor
            key={index}
            className={classes.link}
            component="a"
            href={link.link}
            target="_blank"
          >
            {link.label}
          </Anchor>
        );
      }

      return (
        <Text key={index} className={classes.link}>
          {link.label}
        </Text>
      );
    });

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner} size="xl">
        <Box mb="md">
          <Image src={shepherdLogo} alt="logo" width={133} />
          {/* <Text size="xs" c="dimmed" className={classes.description}>
            Insurance and beyond
          </Text> */}
        </Box>
        <SimpleGrid cols={{ base: 2, md: 3 }}>{groups}</SimpleGrid>
      </Container>
      <Container className={classes.afterFooter} size="xl" bg="shepherdGray.1">
        <Flex
          direction={{ base: 'column-reverse', md: 'row' }}
          gap={8}
          w="100%"
          align={{ base: 'start' }}
          justify={{ md: 'space-between' }}
        >
          <Text c="dimmed" size="sm">
            © {new Date().getFullYear()} Shepherd Labs, Inc. All rights reserved
          </Text>

          <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
            <ActionIcon
              size="lg"
              color="shepherdGray.5"
              variant="subtle"
              component="a"
              href="https://twitter.com/withshepherd"
              target="_blank"
            >
              <SvgTwitter width={24} height={24} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              color="shepherdGray.5"
              variant="subtle"
              component="a"
              href="https://linkedin.com/company/withshepherd"
              target="_blank"
            >
              <SvgLinkedin width={24} height={24} />
            </ActionIcon>
          </Group>
        </Flex>
      </Container>
    </footer>
  );
};

export default Footer;
