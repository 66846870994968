export const FILE_LINKS = {
  privacyPolicyLink:
    'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Privacy+Policy.pdf',
  termsOfUseLink:
    'https://shepherd-public.s3.us-west-1.amazonaws.com/Shepherd+Labs+-+Terms+of+Use.pdf',
};

export const GOLDENGATE_API_URL =
  process.env.NEXT_PUBLIC_GOLDENGATE_API_URL || process.env.GOLDENGATE_API_URL;
export const SEND_TO_SLACK = process.env.SEND_TO_SLACK;
export const SLACK_ACCESS_TOKEN = process.env.SLACK_ACCESS_TOKEN;
export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || process.env.VERCEL_ENV === 'production';

export const DOMAIN = 'shepherdinsurance.com';
export const FULL_DOMAIN = `https://${DOMAIN}`;
