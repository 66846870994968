'use client';
import React from 'react';
import localFont from 'next/font/local';
import {
  MantineProvider,
  CSSVariablesResolver,
  createTheme,
  MantineColorsTuple,
  Button,
  Text,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import buttonClasses from './variants/Button.module.css';
import textClasses from './variants/Text.module.css';

const inter = localFont({
  display: 'auto',
  src: [
    {
      path: './fonts/InterDisplay-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/InterDisplay-Italic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/InterDisplay-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/InterDisplay-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/InterDisplay-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/InterDisplay-BoldItalic.ttf',
      weight: '700',
      style: 'italic',
    },
  ],
  fallback: ['inter', 'system-ui'],
});

const shepherdGray: MantineColorsTuple = [
  '#F9FAFB',
  '#F2F4F7',
  '#EAECF0',
  '#D0D5DD',
  '#98A2B3',
  '#667085',
  '#475467',
  '#344054',
  '#1D2939',
  '#101828',
];

const theme = createTheme({
  fontFamily: inter.style.fontFamily,
  fontFamilyMonospace: inter.style.fontFamily,
  headings: { fontFamily: inter.style.fontFamily },
  colors: {
    shepherdGray,
  },
  // fontSizes: {
  //   xxl: rem(36),
  // },
  components: {
    Button: Button.extend({
      classNames: buttonClasses,
    }),
    Text: Text.extend({
      classNames: textClasses,
    }),
  },
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--Shepherd-Gradient': 'linear-gradient(90deg, #FDC331 0%, #FA9742 39.76%, #DA43E7 100%)',
    '--Button-Gradient': 'linear-gradient(180deg, #525252 0%, #000 100%)',
    // '--mantine-color-body': '#1f1f1f',
  },
  dark: {},
  light: {},
});

const MantineWrapper = ({
  // Layouts must accept a children prop.
  // This will be populated with nested layouts or pages
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <MantineProvider theme={theme} forceColorScheme="light" cssVariablesResolver={resolver}>
      <Notifications />

      <ModalsProvider>{children}</ModalsProvider>
    </MantineProvider>
  );
};

export default MantineWrapper;
