import { SVGProps } from 'react';
import classes from './IconBadge.module.css';
import { Box } from '@mantine/core';

const IconBadge = ({
  icon: Icon,
  alt,
  size = 72,
}: {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  alt: string;
  size?: number;
}) => {
  return (
    <Box className={classes.wrapper} w={size} h={size} style={{ borderRadius: size * 0.125 }}>
      <div className={classes.root} style={{ borderRadius: size * 0.125 }}>
        <Icon width={size / 2} height={size / 2} />
      </div>
    </Box>
  );
};

export default IconBadge;
