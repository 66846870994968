import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 24" {...props}>
    <path
      fill="#667085"
      d="M15 11.063a2.8 2.8 0 0 0 2.803-2.813A2.8 2.8 0 0 0 15 5.438a2.81 2.81 0 0 0-2.812 2.812A2.81 2.81 0 0 0 15 11.063m-7.5 0a2.8 2.8 0 0 0 2.803-2.813A2.8 2.8 0 0 0 7.5 5.438 2.81 2.81 0 0 0 4.688 8.25 2.81 2.81 0 0 0 7.5 11.063m0 1.874c-2.184 0-6.562 1.097-6.562 3.282v1.406a.94.94 0 0 0 .937.938h11.25a.94.94 0 0 0 .938-.938v-1.406c0-2.185-4.379-3.282-6.563-3.282m7.5 0c-.272 0-.581.02-.91.047.02.01.029.028.038.038 1.069.778 1.81 1.819 1.81 3.197v1.406c0 .328-.066.647-.17.938h4.857a.94.94 0 0 0 .938-.938v-1.406c0-2.185-4.379-3.282-6.563-3.282"
      opacity={0.8}
    />
  </svg>
);
export default SvgPeople;
