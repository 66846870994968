import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWork = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 24" {...props}>
    <path
      fill="#667085"
      d="M18.75 6.844H15V4.969c0-1.04-.834-1.875-1.875-1.875h-3.75c-1.04 0-1.875.834-1.875 1.875v1.875H3.75a1.86 1.86 0 0 0-1.866 1.875L1.875 19.03c0 1.04.834 1.875 1.875 1.875h15c1.04 0 1.875-.834 1.875-1.875V8.72c0-1.04-.834-1.875-1.875-1.875m-5.625 0h-3.75V4.969h3.75z"
      opacity={0.8}
    />
  </svg>
);
export default SvgWork;
